import { render, staticRenderFns } from "./updateJyPwd.vue?vue&type=template&id=08d55ee8&scoped=true&"
import script from "./updateJyPwd.vue?vue&type=script&lang=js&"
export * from "./updateJyPwd.vue?vue&type=script&lang=js&"
import style0 from "./updateJyPwd.vue?vue&type=style&index=0&id=08d55ee8&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d55ee8",
  null
  
)

export default component.exports